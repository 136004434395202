@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section {
  padding: 60px 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#home {
  background-image: url('./images/home-bg.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

#about {
  background-image: url('./images/about-bg.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

#services {
  background-image: url('./images/services-bg.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

#portfolio {
  background-image: url('./images/portfolio-bg.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

/* #contact {
  background-image: url('./images/contact-bg.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
} */
